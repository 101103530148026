/* CSS for lcbc.uio.no */

@import "../../../../../../lib/uio2/css/base/base.less";
@import "../../../../../../lib/uio2/css/typography.less";
@import "../../../../../../lib/uio2/css/grid.less";
@import "../../../../../../lib/uio2/css/components.less";
@import "../../../../../../lib/uio2/css/documents.less";
@import "../../../../../../lib/uio2/css/listings.less";
@import "../../../../../../lib/uio2/css/search.less";

@responsiveConsentBreakpoint: ~"only screen and (max-width: 768px)";
@tabletConsentBreakpoint: ~"only screen and (max-width: 1070px)";
@import '../../../../../groups/all/src/resources/css/consent.less';

@import "../../../../../../lib/uio2/css/frontpage-edit.less";
@import "../../../../../../lib/uio2/css/embedded.less";
@import "../../../../../../lib/uio2/css/print.less";

@import '../../../../../groups/all/src/resources/css/head-language-switch.less';

@import "../../../../../groups/design-uio2/src/resources/css/color-profile/color-profile.less";
@import "../../../../../groups/design-uio2/src/resources/css/color-profile/green-profile.less";

@linkColor: #2143ef;
#head-wrapper {    
    padding: 25px 10px;
    #head {
        column-gap: 1.2em;
        row-gap: 3em;
        #header {
            a.logo {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                text-decoration: none;
                height: 50px;
                img {
                    height: 50px;
                }
            }
            span {
                margin-top: 0.1em;
            }
        }
    }
}