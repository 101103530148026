/* ---- colors ---- */
@color-light-bg: #6dce88; //light mint green
@color-dark-bg: #244537; //dark green
@color-light: #ffffff;
@color-neutral: #ccc;
@color-neutral-dark: #18191C; //charcoal
@color-dark: #000000;
@color-placeholder: #ffc0cb;
@headerLangTooltipBg: #312d28;

:root {
  --textColor: @color-dark;
  --infoBoxDefaultColor: @color-dark-bg;
  --alternateRowColor: #DEE3E1; 
  --alternateRowColorHover: #D1D6D4;
  --grid-color-profile: #96ADA3;
}

#main .grid-container.row-all-colored.grid-color-green {
  background: var(--grid-color-profile);

  .vrtx-frontpage-box.green-box {
    background-color: transparent;
  }
}